<template>
  <div>
    <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
      <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    <v-toolbar v-if="Array.isArray(offeringinfo) &&
      offeringinfo.length" dense class="tc-title">
      <v-toolbar-title><span style="font-weight:bold"> {{ offeringinfo[0].company }} :
          {{ offeringinfo[0].code }}</span></v-toolbar-title>


    </v-toolbar>
    <v-toolbar v-else dense class="tc-title">
     <v-row justify="space-between" align="center"> Send Email <v-btn class="primary" @click="mail_history_dialog=true"> Mail History</v-btn></v-row>


    </v-toolbar>
    <v-dialog max-width="800" v-model="mail_history_dialog">
        <v-card >
          <v-card-title class="tc-title">
            Mail History
          </v-card-title>
            <v-card-text>
                <v-row justify="center">  <v-col cols="12" sm="5" md="5" ><v-text-field v-model="selected_histoy_date"  outlined type="date"></v-text-field> </v-col>  <v-col cols="12" sm="4" md="4" ><v-btn class=" mt-2 tc-title" @click="gethistory()">get history</v-btn> </v-col>  </v-row>
                <v-skeleton-loader class="ma-3 pa-2" v-if="dialog_loading" v-bind="attrs" type="list-item-three-line"></v-skeleton-loader>
                <div v-else>


                  <v-data-table
    :headers="send_mail_header"
    :items="mail_history_data"
    class="elevation-1"
    :search="search"
  >
    
  </v-data-table>
                  </div>
              </v-card-text>
          </v-card>
      </v-dialog>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-card>
      <v-container fluid>
        <v-row>
          <v-col sm="4">
            <label><strong>Status : </strong></label>
            <v-select dense outlined v-model="status" :items="status_list" persistent-hint></v-select>
          </v-col>
         

        </v-row>
        <v-row v-if="status == 'Comma Seperated PRN'">
          <v-col cols="12" sm="12" md="12">
            <label><strong>Comma Separated PRN</strong></label>
            <br><br>
            <v-textarea height="60px" label="Please enter Comma separated PRN's" v-model="prn">
            </v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-btn style="margin-left:700px;" class=" ma-5 mt-8 center" dark color="indigo"
            @click="statuswisestudentforemail()">
            Get Students</v-btn>
        </v-row>
        <v-row v-if="  Array.isArray(learnerlist) &&
            learnerlist.length">
          <v-simple-table>
            <thead>
              <tr>
                <th class="text-left">Sr. No</th>
                <th class="text-left">
                  <v-row>
                    Selected
                    <input v-model="selectall" class="ml-2" style="width: 20px; height: 20px" type="checkbox"
                      @click="checkAll(learnerlist)" />
                  </v-row>
                </th>
                <th v-if="commode == 'WhatsApp'" class="text-left">Message</th>
                <th class="text-left">Name</th>
                <th class="text-left">PRN/GRNo</th>
                <th class="text-left">WhatsApp Contact</th>
                <th class="text-left">Email Id</th>
                <th class="text-left">Program</th>
                <th class="text-left">Year</th>
                <th class="text-left">Organization</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in learnerlist" :key="index">
                <td>{{ index + 1 }}</td>
                <td>
                  <input style="height: 40px; width: 30px" type="checkbox" v-model="item.isselected" />

                </td>
                <td v-if="commode == 'WhatsApp'">
                  <v-avatar style="" size="25" class="edit-avatar">
                    <v-icon class="mr-2  v-icon-size" color="success" @click="showmsg(item)">mdi-eye-outline</v-icon>
                  </v-avatar>
                </td>
                <td>{{ item.name }}</td>
                <td>{{ item.reg_number }}</td>
                <td>{{ item.mobilenumber }}</td>
                <td>{{ item.email }}</td>
                <td>{{ item.program }}</td>
                <td>{{ item.year }}</td>
                <td>{{ item.org }}</td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-row>
        <div v-if="  Array.isArray(learnerlist) &&
            learnerlist.length">
        <v-row>
          <v-col cols="12" sm="4" md="4">
            <label><strong>Communication mode</strong></label>
            <!-- <v-text-field v-model="commode" dense
                          hint="SMS,Email"
                          type="text"
                        outlined
                          readonly
                          ></v-text-field> -->
            <v-select dense outlined v-model="commode" :items="modelist" persistent-hint></v-select>
          </v-col>
        </v-row>
        <!-- <v-row v-if="this.commode =='Email'">
                    <v-col cols="12" sm="8" md="8">
                   <v-file-input type="file" placeholder="Select Attachment" prepend-icon="mdi-file" ref="file" show-size  v-model="file" required="true" clearable></v-file-input>
                  </v-col>
            </v-row> -->
        <span v-if="this.commode == 'Email'">
          <v-row>
            <v-col cols="12" sm="8" md="8">
              <v-file-input type="file" placeholder="Select Attachment 1" prepend-icon="mdi-file" ref="file" show-size
                v-model="file" required="true" clearable></v-file-input>
            </v-col>
            <div v-show="this.filecount < 1">
              <v-btn color="primary" class="logi" fab small dark @click="addRow">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </div>
          </v-row>
          <v-row v-if="isshowplus == true">
            <v-col cols="12" sm="8" md="8">
              <v-file-input type="file" placeholder="Select Attachment 2" prepend-icon="mdi-file" ref="file" show-size
                v-model="file1" required="true" clearable></v-file-input>
            </v-col>
            <div v-show="this.filecount < 2">
              <v-btn color="primary" class="logi" fab small dark @click="addRow1">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </div>
          </v-row>
          <v-row v-if="isshowplus1 == true">
            <v-col cols="12" sm="8" md="8">
              <v-file-input type="file" placeholder="Select Attachment 3" prepend-icon="mdi-file" ref="file" show-size
                v-model="file2" required="true" clearable></v-file-input>
            </v-col>
            <div v-show="this.filecount < 3">
              <v-btn color="primary" class="logi" fab small dark @click="addRow2">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </div>
          </v-row>
          <v-row v-if="isshowplus2 == true">
            <v-col cols="12" sm="8" md="8">
              <v-file-input type="file" placeholder="Select Attachment" prepend-icon="mdi-file" ref="file" show-size
                v-model="file3" required="true" clearable></v-file-input>
            </v-col>

            <div v-show="this.filecount < 4">
              <v-btn color="primary" class="logi" fab small dark @click="addRow3">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </div>
          </v-row>
          <v-row v-if="isshowplus3 == true">
            <v-col cols="12" sm="8" md="8">
              <v-file-input type="file" placeholder="Select Attachment" prepend-icon="mdi-file" ref="file" show-size
                v-model="file4" required="true" clearable></v-file-input>
            </v-col>

            <div v-show="this.filecount < 5">
              <v-btn color="primary" class="logi" fab small dark @click="addRow4">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </div>
          </v-row>
          <v-row v-if="isshowplus4 == true">
            <v-col cols="12" sm="8" md="8">
              <v-file-input type="file" placeholder="Select Attachment" prepend-icon="mdi-file" ref="file" show-size
                v-model="file5" required="true" clearable></v-file-input>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="8" md="8">
              <label><strong>Subject</strong></label>
              <v-textarea v-model="subject" rows="2" outlined>
              </v-textarea>
            </v-col>
          </v-row>


        </span>
        <v-row v-if="Array.isArray(offeringinfo) &&
      offeringinfo.length">
            <v-col cols="12" sm="12" md="12">
              Company: {{ offeringinfo[0].company }}<br>
              Registration Start Date Time :: {{ regstartdate }} - {{ offeringinfo[0].regstarttime }}<br>
              Registration End Date Time:: {{ regenddate }} - {{ offeringinfo[0].regendtime }}<br>
              <br>
              </v-col>
          </v-row>
          <v-row>
          <v-col cols="12" sm="12" md="12">
           
              <label><strong>Body</strong></label>
              <!-- <v-textarea v-model="body" rows="8" outlined>
                          </v-textarea> -->
              <ckeditor v-model="body" :config="editorConfig" style="margin: 8px;" class="pt-0 pb-0"></ckeditor>
            </v-col>
            <br>
            <v-col cols="12" sm="12" md="12">

              <label><strong>Additional email ID's</strong></label>
              <br><br>
              <v-textarea height="60px" label="Please enter semicolan separated email ID's" v-model="emails">
              </v-textarea>
            </v-col>
            </v-row>
        <span v-if="this.commode == 'WhatsApp'">
          <v-row>
            <v-col cols="12" sm="4" md="4">
              <label><strong>WhatsApp Template</strong></label>
              <v-select dense outlined v-model="template" :items="templatelist" persistent-hint></v-select>
            </v-col>
            <v-btn style="margin-left:700px;" class="logi ma-5 mt-8 center" dark color="indigo"
              @click="synchtemplate()">
              Synch Template </v-btn>


          </v-row>
        </span>
       
       
        <v-row v-if="status == 'Eligible Program/Branch'">
          <!-- <v-simple-table>
            <thead>
              <tr>
                <th class="text-left">Sr. No</th>
                <th class="text-left">
                  <v-row>
                    Selected
                    <input v-model="selectall" class="ml-2" style="width: 20px; height: 20px" type="checkbox"
                      @click="checkAll(learnerlist)" />
                  </v-row>
                </th>
                <th class="text-left">Email Id</th>
                <th class="text-left">Program</th>
                <th class="text-left">Year</th>
                <th class="text-left">Organization</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in learnerlist" :key="index">
                <td>{{ index + 1 }}</td>
                <td>
                  <input style="height: 40px; width: 30px" type="checkbox" v-model="item.isselected" />
                </td>
                <td>{{ item.email }}</td>
                <td>{{ item.program }}</td>
                <td>{{ item.year }}</td>
                <td>{{ item.org }}</td>
              </tr>
            </tbody>
          </v-simple-table> -->
        </v-row>

        
        <v-dialog v-model="nonumber_dialog" max-width="500px">
          <v-card>
            <v-form v-model="valid">
              <v-card-title>
                <v-row justify="space-between">
                  <span class="headline" style="margin-left:20px">Whatsapp contact not available</span>
                  <v-btn icon dark @click="nonumber_dialog = false">
                    <v-icon color="black">mdi-close</v-icon>
                  </v-btn>
                </v-row>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-data-table :headers="headers1" :items="no_not_avail_array" :search="search"></v-data-table>
                </v-container>
              </v-card-text>
            </v-form>
          </v-card>
        </v-dialog>

        <v-dialog v-model="wamsg_dialog" max-width="500px">
          <v-card>
            <v-card-title>
              <v-row justify="space-between">
                <v-spacer></v-spacer>
                <v-btn icon dark @click="wamsg_dialog = false">
                  <v-icon color="black">mdi-close</v-icon>
                </v-btn>
              </v-row>
            </v-card-title>
            <v-card-text>
              <v-container>
                {{ waMsg }}
              </v-container>
            </v-card-text>
          </v-card>
        </v-dialog>
        <br><br>
        <v-row>
          <div>
            <!-- <v-btn v-if="commode=='Email'"
                        class="logi ma-5 mt-8 center"
                        dark
                        color="indigo"
                        @click="sendmailtostudentswithattach()"
                    >
                        Send Notification</v-btn>
                        <v-btn v-else -->
            <v-btn class="logi ma-5 center" dark color="indigo" @click="sendmailtostudentswithattach()">
              Send Notification</v-btn>
          </div>

        </v-row>
      </div>
      </v-container>


    </v-card>
  </div>
</template>
<script>

import axios from "axios";
export default {
  data: () => ({
    editorConfig: {
      extraPlugins: "mathjax",
      extraPlugins: "colorbutton",
      mathJaxLib: "https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.1/MathJax.js?config=TeX-AMS_HTML",
    },
    mail_history_dialog:"",
    selected_histoy_date:"",
    prn:"",
    dialog_add: false,
    dialog_upload: false,
    search: "",
    selected_company: null,
    wamsg_dialog: false,
    items: "",
    nameidmap: "",
    file: "",
    snackbar: "",
    selectall: false,
    isselected: false,
    snackbar_msg: "",
    body: "",
    subject: "",
    file: null,
    file1: null,
    file2: null,
    file3: null,
    file4: null,
    file5: null,
    filecount: 0,
    isshowplus: false,
    isshowplus1: false,
    overlay: false,
    nonumber_dialog: false,
    no_not_avail_array: [],
    commode: "",
    learnerlist: [],
    offeringinfo: [],
    regstartdate: "",
    regenddate: "",
    status_list: [],
    templatelist: [],
    template: "",
    modelist: [],
    emails: "",
    headers1: [
      { text: "Name", value: "name", sortable: true },
      { text: "Gr No", value: "grno", sortable: true },

    ],
  send_mail_header: [
      { text: "Date", value: "date", sortable: true },
      { text: "Send From", value: "from", sortable: true },
      { text: "Send To", value: "to", sortable: true },
      { text: "Message", value: "message", sortable: true },
     

    ],
    dialog_loading:false,
      mail_history_data:[],
    status: null,
    color: "",
    scheduling_id: null,
  }),
  watch: {
    dialog_edit(val) {
      val || this.close();
    },
  },

  methods: {
    gethistory()
    {
      this.dialog_loading = true;
      const data = {
        date:this.selected_histoy_date,
      };
      axios
        .post("/TPOCompanyScheduling/getmailhistory", data)
        .then((res) => {
          if (res.data.msg == "200") {
            this.dialog_loading = false;
            this.mail_history_data=res.data.mail_history_data;
          }
          else
          {
            this.dialog_loading = false;
          }
        })
     
    },
    showSnackbar(clr, msg) {
      this.snackbar = true;
      this.color = clr;
      this.snackbar_msg = msg;
    },

    addRow() {
      this.isshowplus = true
      this.filecount++;
      //console.log("filecount addrow");
      //console.log(this.filecount);
    },
    addRow2() {
      this.isshowplus2 = true
      this.filecount++;
      //console.log("filecount addrow2");
      //console.log(this.filecount);
    },
    addRow3() {
      this.isshowplus3 = true
      this.filecount++;
      //console.log("filecount addrow3");
      //console.log(this.filecount);
    },
    addRow4() {
      this.isshowplus4 = true
      this.filecount++;
      //console.log("filecount addrow4");
      //console.log(this.filecount);
    },
    addRow1() {
      this.isshowplus1 = true
      this.filecount++;
      //console.log("filecount addrow1");
      //console.log(this.filecount);
    },
    checkAll(hello) {

      for (var i = 0; i < this.learnerlist.length; i++) {
        if (!this.selectall) {
          this.learnerlist[i].isselected = true;
        } else {
          this.learnerlist[i].isselected = false;
        }
      }
    },

    onLoad() {
      // alert("in onload send email");
      if (!localStorage.getItem("scheduling_id")) {
        this.modelist = ['Email'];
        this.status_list = ['Comma Seperated PRN'];

      } else {
        var offering = {
          id: localStorage.getItem("scheduling_id")
        };
        axios
          .post("/TPOCompanyScheduling/onsendemailpage", offering)
          .then((res) => {
            if (res.data.msg == "200") {
              //4CAF50
              this.modelist = res.data.modelist;
              this.offeringinfo = res.data.offeringinfo;
              this.status_list = res.data.status_list;
              this.templatelist = res.data.templatelist;
              if (this.offeringinfo[0].regenddate)
                this.regenddate = this.offeringinfo[0].regenddate.split("T")[0]
              if (this.offeringinfo[0].regstartdate)
                this.regstartdate = this.offeringinfo[0].regstartdate.split("T")[0]
              // this.learnerlist = res.data.learnerlist;
            }
          })
          .catch((error) => {
            this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
          })
          .finally(() => { });
      }
    },
    synchtemplate() {
      var routerpush = this.$router.resolve({
        name: "synch-whatsapp-template",
      })
      window.open(routerpush.href, '_blank');
    },

    statuswisestudentforemail() {
      if (this.status == null) {
        this.showSnackbar("#b71c1c", "Select Status.");
        return;
      }
      if(this.status=='Comma Seperated PRN' && !this.prn)
      {
        this.showSnackbar("#b71c1c", "Please Enter PRN");
        return;
      }
      this.overlay = true;
      const data = {
        status: this.status,
        prn:this.prn,
        id: localStorage.getItem("scheduling_id"),
      };
      axios
        .post("/TPOCompanyScheduling/statuswisestudentforemail", data)
        .then((res) => {
          if (res.data.msg == "200") {
            //4CAF50
            this.learnerlist = res.data.learnerlist;
            this.commode = 'Email'
            this.overlay = false;
            //console.log(" res.data.learnerlist");
            //console.log( res.data.learnerlist);
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
        })
        .finally(() => { });
    },

    sendmailtostudents() {
      this.overlay = true;

      if (this.commode == "Email") {
        if (this.body == "") {
          this.showSnackbar("#b71c1c", "Please enter BODY ");
          this.overlay = false;
          return;
        }
        if (this.subject == "") {
          this.showSnackbar("#b71c1c", "Please enter SUBJECT  ");
          this.overlay = false;
          return
        }

      } else if (this.commode == "WhatsApp") {
        if (this.template == "") {
          this.showSnackbar("#b71c1c", "Please Select Template");
          this.overlay = false;
          return
        }
      }
      const data = {
        body: this.body,
        subject: this.subject,
        commode: this.commode,
        status: this.status,
        list: this.learnerlist,
        template: this.template,
        offering: this.offeringinfo[0],
        emails: this.emails,

      };
      axios
        .post("/TPOCompanyScheduling/sendmailtostudents", data)
        .then((res) => {
          if (res.data.code == "200") {
            //4CAF50
            this.overlay = false;
            this.no_not_avail_array = res.data.no_not_avail_array;
            //console.log("no number")
            //console.log(this.no_not_avail_array)
            if (this.no_not_avail_array.length > 0) {
              this.nonumber_dialog = true;
            }
            this.showSnackbar("green", "Notification Sent Successfully");
          } else if (res.data.code == "NA") {
            this.showSnackbar("#b71c1c", "Please contact TPO to enable WhasApp service...");
            this.overlay = false;
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
        })
        .finally(() => { });
    },
    sendmailtostudentswithattach() {
      this.overlay = true;
      if (this.commode == "Email") {
        if (this.body == "") {
          this.showSnackbar("#b71c1c", "Please enter BODY ");
          this.overlay = false;
          return;
        }
        if (this.subject == "") {
          this.showSnackbar("#b71c1c", "Please enter SUBJECT ");
          this.overlay = false;
          return;
        }
        // for (var i = 0; i < this.learnerlist.length; i++) {
        //   if (!this.learnerlist[i].isselected) {
        //     this.showSnackbar("#b71c1c", "Please Select Student ");
        //     return;
        //   }
        // }


      } else if (this.commode == "WhatsApp") {
        if (this.template == "") {
          this.showSnackbar("#b71c1c", "Please Select Template");
          this.overlay = false;
          return
        }
      }

      //console.log("gilleee 5555555");
      //console.log(this.file5);

      let formData = new FormData();
      if (this.file == null)
        formData.append("file", "NA");
      else
        formData.append("file", this.file);

      if (this.file1 == null)
        formData.append("file1", "NA");
      else
        formData.append("file1", this.file1);

      if (this.file2 == null)
        formData.append("file2", "NA");
      else
        formData.append("file2", this.file2);


      if (this.file3 == null)
        formData.append("file3", "NA");
      else
        formData.append("file3", this.file3);

      if (this.file4 == null)
        formData.append("file4", "NA");
      else
        formData.append("file4", this.file4);

      if (this.file5 == null)
        formData.append("file5", "NA");
      else
        formData.append("file5", this.file5);
      //  const data= {
      //     body:this.body,
      //     subject:this.subject,
      //     commode:this.commode,
      //     status:this.status,
      //     list : this.learnerlist,
      //     template:this.template,
      //     offering:this.offeringinfo[0],
      // };

      formData.append("body", this.body);
      formData.append("subject", this.subject);
      formData.append("commode", this.commode);
      formData.append("status", this.status);
      formData.append("emails", this.emails);
      let newarray = [];
      // for(var k in this.learnerlist){
      //  let array= {}
      //   array.push("name",this.learnerlist[k].name ),
      //   array.push("isselected",this.learnerlist[k].isselected),
      //   array.push("reg_number",this.learnerlist[k].reg_number),          
      //   console.log(array);
      //   newarray.push(array);
      // }
      //console.log("newarray ")
      var data = JSON.stringify(this.learnerlist);
      //console.log(data)
      formData.append("list", data);
      formData.append("template", this.template);
      formData.append("offering", this.offeringinfo[0]);

      axios
        .post("/TPOCompanyScheduling/sendmailtostudentswithattach", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          }
        })
        .then((res) => {
          if (res.data.code == "200") {
            //4CAF50
            this.overlay = false;
            this.showSnackbar("green", "Notification Sent Successfully");
            if (this.no_not_avail_array.length > 0) {
              this.nonumber_dialog = true;
            }
          } else if (res.data.code == "NA") {
            this.showSnackbar("#b71c1c", "Please contact TPO to enable WhasApp service...");
            this.overlay = false;
          } else {
            this.showSnackbar("#b71c1c", "Mail not sent");
            this.overlay = false;
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", res.data.error); // show snackbar
        })
        .finally(() => { });
    },

    showmsg(item) {
      if (this.template == "") {
        this.showSnackbar("#b71c1c", "Select Template .");
        return;
      }
      this.overlay = true;
      const data = {
        status: item,
        template: this.template,
        id: localStorage.getItem("scheduling_id"),
      };
      axios
        .post("/TPOCompanyScheduling/showwhatsappmsg", data)
        .then((res) => {
          if (res.data.msg == "200") {
            //4CAF50
            this.waMsg = res.data.waMsg;
            this.wamsg_dialog = true;
            this.overlay = false;

          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
        })
        .finally(() => { });
    },



  },
  mounted() {

    this.onLoad();
  },
};
</script>
<style scoped>
.ec-title {
  background-image: linear-gradient(-100deg, #f4faba, #d64907);
  color: #fff;
}

.c-title {
  background-image: linear-gradient(-90deg, #befcf7, #03948d);
  color: #fff;
}

.tc-title {
  background-image: linear-gradient(-90deg,skyblue, #057996);
  color: #fff;
  border-radius: 3px;
}

.logi {
  background-image: linear-gradient(-100deg, #8aa5f7, #063bdb);
  color: #fff;
}

.card-title {
  background-image: linear-gradient(-90deg, #8edffd, #034c70);
  color: #fff;
}

.lbl {
  color: #1705e6;
}

.c-title1 {
  background-image: linear-gradient(-90deg, #dbdbdb, #012944);
  color: #fff;
}

.link-icon {
  color: #fff;
}

.logi2 {
  background: linear-gradient(-90deg, #30cfd0 0%, #330867 100%);
}
</style>